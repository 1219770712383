import React from 'react'

import Layout from '../layouts/en'
import NotFound from '../components/NotFound'

const NotFoundPage = () => (
  <Layout>
    <NotFound />
  </Layout>
)

export default NotFoundPage
