import React from 'react'
import { Link } from 'gatsby'

import { useTranslation, useLocale } from '../utils'

import SEO from './seo'
import BackText from './BackText'
import HeadingLine from './HeadingLine'

const NotFound = () => {
  const { homeUrl } = useLocale()
  const t = useTranslation()

  return (
    <>
      <SEO title={'meta.notFound'} />
      <div className="p-not-found  c-section">
        <div className="c-section__align">
          <div className="c-section__wrapper">
            <div className="c-section__content">
              <BackText aria-hidden='false'>404</BackText>
              <h1 className="c-section-heading c-section-heading--back-text">
                {t('meta.notFound')}
                <HeadingLine />
              </h1>
              <div className="ctx-content">
                <p>
                  {t('meta.notFoundDescription', {
                    homeLink: <Link to={homeUrl} key="notFound">{t('glossary.homePage')}</Link>
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
